import React, { useState } from 'react';
import { Phone, Facebook, Youtube, Lightbulb, Instagram, Twitter, Linkedin, CheckCircle, Award, Shield, FileCheck, MapPin, Users, Target, Briefcase, Menu, X } from 'lucide-react';

const services = [
  {
    title: "Trademark Services",
    description: "Secure your brand identity with our comprehensive trademark registration and protection services.",
    icon: Shield,
  },
  {
    title: "ISO Registration",
    description: "Achieve international quality standards with our expert ISO registration and compliance services.",
    icon: Award,
  },
  {
    title: "Design Registration",
    description: "Protect your creative designs, from logos to product designs, with our design registration services.",
    icon: FileCheck,
  },
];

const features = [
  "Expert legal team",
  "Fast and efficient process",
  "Comprehensive protection",
  "Ongoing support",
  "Competitive pricing",
];

const clientReviews = [
  { id: "CQbqLhNgtAg?si=AFoAR26FhDJwA6-x", title: "Great experience with Trademark Club" },
  { id: "ZxzcvdWVAao?si=dJYkZxVUTmB5z5g8", title: "How Trademark Club protected our brand" },
  { id: "4AOtFSIKQ5I?si=KiD-xoPJ71dFLWbd", title: "ISO registration made easy" },
  { id: "d13NxVmqvEQ?si=IcIyEqFeiMPIG63Q", title: "Design registration success story" },
];

const aboutUsInfo = [
  {
    title: "Our Mission",
    description: "To safeguard intellectual property and foster innovation by providing expert, accessible legal services.",
    icon: Target,
  },
  {
    title: "Our Team",
    description: "A diverse group of experienced attorneys and consultants dedicated to protecting your intellectual assets.",
    icon: Users,
  },
  {
    title: "Our Commitment",
    description: "With passion and innovation, we deliver tailored IP solutions, helping businesses confidently navigate complex landscapes.",
    icon: Lightbulb,
  },
//   {
//     title: "Our Experience",
//     description: "Over 15 years of successfully navigating complex IP landscapes for businesses of all sizes.",
//     icon: Briefcase,
//   },
];

const TrademarkClubWebsite = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 font-sans">
      <header className="bg-gray-900 text-white p-4 sticky top-0 z-10">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full mr-3">
            <img src="\logo_black.png" alt="logo" width="500" height="600"/>
            </div>
            <h1 className="text-xl md:text-2xl font-bold">Trademark Club</h1>
          </div>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#about" className="hover:text-yellow-400">About</a></li>
              <li><a href="#services" className="hover:text-yellow-400">Services</a></li>
              <li><a href="#reviews" className="hover:text-yellow-400">Reviews</a></li>
              <li><a href="#contact" className="hover:text-yellow-400">Contact</a></li>
            </ul>
          </nav>
          <button onClick={toggleMobileMenu} className="md:hidden">
            {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {mobileMenuOpen && (
          <nav className="mt-4 md:hidden">
            <ul className="flex flex-col space-y-2">
              <li><a href="#about" className="block py-2 hover:text-yellow-400" onClick={toggleMobileMenu}>About</a></li>
              <li><a href="#services" className="block py-2 hover:text-yellow-400" onClick={toggleMobileMenu}>Services</a></li>
              <li><a href="#reviews" className="block py-2 hover:text-yellow-400" onClick={toggleMobileMenu}>Reviews</a></li>
              <li><a href="#contact" className="block py-2 hover:text-yellow-400" onClick={toggleMobileMenu}>Contact</a></li>
            </ul>
          </nav>
        )}
      </header>

      <main>
        <section className="bg-gray-900 text-white py-16 md:py-20">
          <div className="container mx-auto text-center px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Protect Your Intellectual Property</h2>
            <p className="text-lg md:text-xl mb-8">Your One-Stop Solution for Trademark Services, ISO Registration, and Design Registration</p>
            <a href="#contact" className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-full text-lg font-semibold hover:bg-yellow-300 transition duration-300">Get Started</a>
          </div>
        </section>

        <section id="about" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">About Trademark Club</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
                  {aboutUsInfo.map((info, index) => (
                    <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-md">
                      <div className="flex items-center mb-2">
                        <info.icon className="w-6 h-6 text-yellow-400 mr-2" />
                        <h3 className="text-lg font-semibold">{info.title}</h3>
                      </div>
                      <p className="text-sm">{info.description}</p>
                    </div>
                  ))}
                </div>
                <p className="text-lg">
                  Trademark Club has been at the forefront of intellectual property protection. 
                  Our commitment to excellence and client satisfaction has made us a trusted partner for businesses 
                  ranging from startups to multinational corporations. With a team of skilled attorneys and a 
                  track record of success, we're here to ensure your ideas and innovations are protected in the global marketplace.
                </p>
              </div>
              <div className="aspect-w-16 aspect-h-9 mt-8 md:mt-0">
                <iframe
                  src="https://www.youtube.com/embed/WazS27ipyIg?si=ghQx8DXRcRsaVGTO"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full rounded-lg shadow-lg"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                  <service.icon className="w-12 h-12 text-yellow-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">Why Choose Trademark Club?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <p className="text-lg mb-6">At Trademark Club, we understand the value of your intellectual property. Our team of seasoned professionals is dedicated to providing top-notch services to protect your brand and innovations.</p>
                <ul className="space-y-2">
                  {features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <CheckCircle className="text-yellow-400 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gray-100 p-8 rounded-lg shadow-md mt-8 md:mt-0">
                <h3 className="text-2xl font-semibold mb-4">Our Commitment</h3>
                <p className="mb-4">We're committed to providing exceptional service and results for our clients. Our track record speaks for itself:</p>
                <div className="grid grid-cols-2 gap-4 text-center">
                  {/* <div>
                    <p className="text-3xl font-bold text-yellow-400">500+</p>
                    <p>Trademarks Registered</p>
                  </div> */}
                  {/* <div>
                    <p className="text-3xl font-bold text-yellow-400">100%</p>
                    <p>Tailored IP Solutions Delivered</p>
                  </div> */}
                  <div>
                    <p className="text-3xl font-bold text-yellow-400">98%</p>
                    <p>Client Satisfaction</p>
                  </div>
                  <div>
                    <p className="text-3xl font-bold text-yellow-400">99%</p>
                    <p>Accuracy in Filing & Compliance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="reviews" className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Client Reviews</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {clientReviews.map((review) => (
                <div key={review.id} className="bg-white p-4 rounded-lg shadow-md">
                  <div className="aspect-w-9 aspect-h-16 mb-4">
                    <iframe
                      src={`https://www.youtube.com/embed/${review.id}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full rounded"
                    ></iframe>
                  </div>
                  <h3 className="text-sm font-semibold text-center">{review.title}</h3>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="bg-gray-900 text-white py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">Get in Touch</h2>
            <div className="max-w-4xl mx-auto bg-white text-gray-800 p-8 rounded-lg shadow-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <p className="mb-6">Ready to protect your intellectual property? Contact us today for a free consultation.</p>
                  <div className="flex items-center mb-4">
                    <Phone className="text-yellow-400 mr-2 flex-shrink-0" />
                    <a href="tel:+919904490406" className="hover:text-yellow-400">+91 9904490406</a>
                  </div>
                  <div className="flex space-x-4 mb-6">
                    <a href="https://www.facebook.com/TrademarkClub.in" className="text-gray-600 hover:text-yellow-400"><Facebook size={24} /></a>
                    <a href="https://www.instagram.com/trademarkclub.in/" className="text-gray-600 hover:text-yellow-400"><Instagram size={24} /></a>
                    <a href="https://www.youtube.com/@TRADEMARKCLUB" className="text-gray-600 hover:text-yellow-400"><Youtube size={28} /></a>
                    {/* <a href="#" className="text-gray-600 hover:text-yellow-400"><Linkedin size={24} /></a> */}
                  </div>
                  <div className="flex items-center">
                    <MapPin className="text-yellow-400 mr-2 flex-shrink-0" />
                    <p>239, Avadh Viceroy, Sarthana, Surat</p>
                  </div>
                </div>
                <div className="h-64 md:h-auto mt-8 md:mt-0">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.012715071418!2d72.9009741759735!3d21.231344380788407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0453df4cc43b7%3A0xcc4e69dafb449d84!2sTrademark%20Club!5e0!3m2!1sen!2sin!4v1726064235730!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    className="rounded"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto text-center px-4">
          <p>&copy; 2024 Trademark Club. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default TrademarkClubWebsite;